import * as React from 'react';
import { Appbar } from 'react-native-paper';
import { theme } from '../../global/theme';
import { useStore } from '../../hooks/use-store';

export const Header = ({ hide }: { hide?: boolean }) => {
	const paramStore = useStore();

	return (
		<Appbar.Header style={{ backgroundColor: theme(paramStore.meiInfo.themeColor).colors.primary}}>
			<Appbar.Content style={{ justifyContent: "center", alignItems: "center" }}
				title={hide ? "LOJA NÃO ENCONTRADA" : paramStore.catalogName?.toUpperCase()} />
		</Appbar.Header>
	);
};