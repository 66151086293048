import React from 'react';
import { ActivityIndicator } from 'react-native';

import { ButtonContainer, Title } from './styles';

type Props = {
  disabling?: boolean;
  children?: any;
  type?: 'primary' | 'secondary' | 'secondary_ghost' | "primary_ghost";
  style?: any;
  loading?: boolean;
  onPress?: () => void;
  testID?: string;
  textStyle?: any
};

export const Button: React.FC<Props> = ({
  disabling,
  children,
  type,
  style,
  loading,
  onPress,
  testID,
  textStyle
}) => {
  return (
    <ButtonContainer
      onPress={() => !loading && !!onPress && onPress()}
      disabling={disabling}
      disabled={disabling}
      type={type || 'primary'}
      style={style}
      testID={testID}
    >
      {loading ? (
        <ActivityIndicator color="white" />
      ) : typeof children === 'string' || Array.isArray(children) ? (
        <Title
          disabling={disabling}
          type={type || 'primary'}
          testID="button_title"
          style={textStyle}
        >
          {children}
        </Title>
      ) : (
        children
      )}
    </ButtonContainer>
  );
};
