import { createStackNavigator } from '@react-navigation/stack';
import React from "react";

import Home from "../pages/home";
import Cart from '../pages/cart';

const Stack = createStackNavigator();

export default function Routes() {
	return (
		<Stack.Navigator
			//initialRouteName='Cart'
			screenOptions={{
				headerShown: false,
			}}
		>
			<Stack.Screen
				name="Loja"
				component={Home}
			/>
			<Stack.Screen
				name="Cart"
				component={Cart}
			/>
		</Stack.Navigator>
	);
}
