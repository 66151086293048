import styled from 'styled-components/native';
import { theme } from '../../global/theme';
import { Button } from "../../components/Button";

export const Overlay = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #000000cc;
`;

export const ModalArea = styled.View`
  width: 100%;
  max-width: 90%;
  height: 94%;
  background: #ffffff;
  border-radius: 10px;
`
export const ModalAreaHeader = styled.View`
  width: 100%;
  height: 44px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  align-items: flex-end;
  justify-content: center;
`
export const IconCloseModal = styled.TouchableOpacity<{ color: string }>`
  width: 37px;
  height: 37px;
  align-items: center;
  justify-content: center;
  margin: 8px 8px 0px 0px;
  border:  ${({ color }) => theme().colors.border};
  border-width: 2px;
  border-radius: 10px;
`

export const CloseIcon = styled.Image`
  width: 14px;
  height: 14px;
`

export const ItemsScroll = styled.ScrollView`
  background-color: ${theme().colors.white};
  width: 100%;
  margin-bottom: 30px;
`;

export const ModalContent = styled.View`
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  height: 94%;
`

export const ModalLabelInput = styled.Text`
  font-size: 18px;
  color: #000000;
  font-family: ${theme().fonts.semiBold};
  margin-top: 33px;
  text-align: center;
`

export const InputContentMail = styled.View<{ isFocused: boolean, color: string }>`
  width: 100%;
  height: 55px;
  border-width: 1px;
  border-radius: 10px;
  padding: 10px 0px 0px 17px;
  margin-top: 20px;
  border-color: ${props => props.isFocused ? `${theme(props.color).colors.primary}` : '#e2e2e2'};
  position: relative;
`
export const InputLabel = styled.Text`
  font-size: 12px;
  font-family: ${theme().fonts.regular};
  color: #b7b9c0;
`

export const InputLabelMandatory = styled.Text<{ color: string }>`
  font-size: 12px;
  color: ${({ color }) => theme(color).colors.primary};
`

export const InputTextArea = styled.TextInput`
  border: 0px;
  outlineStyle: none;
`

type ButtonProps = {
  disabled?: boolean;
  onPress: () => void;
};

export const ButtonSend = styled(Button) <{ color: string }>`
  background-color: ${({ color }) => theme(color).colors.primary};
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
`
export const ButtonSendText = styled.Text`
  font-size: 14px;
  font-family: ${theme().fonts.semiBold};
  color: ${theme().colors.white};
`
export const ButtonBack = styled(Button) <{ color: string }>`
  width: 100%;
  border-color: ${({ color }) => theme(color).colors.primary};
`
export const ButtonBackText = styled.Text`
  font-size: 14px;
  font-family: ${theme().fonts.semiBold};
  color: ${theme().colors.primary};
`
export const ErrorMessage = styled.Text`
  color: ${theme().colors.red};
  font-size: 12px;
  font-family: ${theme().fonts.regular};
  position: absolute;
  bottom: -20px;
`
export const Divider = styled.View`
  width: 100%;
  background-color: ${theme().colors.border};
  height: 0.5px;
  margin-top: 15px;
`
export const ContainerFlex = styled.View`
  flex-direction: row;
  gap: 2px;
  width: 100%;
  justify-content: center;
  display: flex;
`
export const Title = styled.Text`
  font-size: 14px;
  font-family: ${theme().fonts.bold};
  color: ${theme().colors.black};
  margin-top: 20px;
`
export const ContainerCards = styled.View`
  padding: 10px;
  margin-top: 10px;
`
export const CardPayment = styled.TouchableOpacity<{ methodSelected: boolean, color: string }>`
  padding: 20px;
  border: 1px solid ${theme().colors.border};
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  background-color: ${({ methodSelected, color }) =>
    methodSelected ? theme(color).colors.primary : theme().colors.white};
`
export const CardPaymentIcon = styled.Image`
  width: 25px;
  height: 25px;
  position: absolute;
  left: 10px;
  `
export const CardPaymentTitle = styled.Text<{ methodSelected: boolean }>`
  font-size: 14px;
  font-family: ${theme().fonts.bold};
  color: ${({ methodSelected }) =>
    methodSelected ? theme().colors.white : theme().colors.black};
`