import styled from "styled-components/native";
import { theme } from "../../global/theme";

import { Dimensions } from "react-native";
import { Button } from '../../components/Button';

import { isMobile } from "react-device-detect"

const { width } = Dimensions.get('window');

export const Modal = styled.Modal.attrs({
	transparent: true,
})``;

const isLargerThan417 = width >= 370 && width <= 412;
const isLargerThan370 = width <= 370 && width >= 325;
const isLessThan320 = width <= 320;


export const Container = styled.View`
	background-color: ${theme().colors.white};
	height: 90%;
	width: ${isMobile ? "95%" : "80%"};
	border-radius: 10px;
`;

export const Content = styled.ScrollView`
  flex: 1;
  margin: 0 20px;
`;

export const BackContainer = styled.View`
	margin-top: 20px;
	margin-left: 20px;
`;

export const BackText = styled.Text`
	padding-bottom: 16px;
	font-size: 14px;
	font-family: ${theme().fonts.bold};
	color: ${theme().colors.primary};
`;

export const AddToCartContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-horizontal: 20px;
  border-top-width: 0.8px;
  border-top-color: #ededed;
  background-color: #fff;
`;

export const ButtonContainerAdd = styled.View`
  width: max-content;
`;

export const OverlayOffer = styled.View`
  padding-top: 10px;
`;

export const ProductBox = styled.View`
  border-width: 0.8px;
  border-color: #ededed;
  border-radius: 10px;
  margin-bottom: 20px;
  padding-bottom: 10px;
`;

export const OfferLabel = styled.Image`
  position: absolute;
  left: 0px;
  top: 1px;
  z-index: 3;
  width: 50px;
`;

export const ImageWrapper = styled.View`
  height: 250px;
	justify-content: center;
	border-radius: 10px;
`;

export const ProductImage = styled.Image`
  height: 160px;
  margin: 2px;
	border-radius: 10px;
`;

export const NoImage = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const ProductName = styled.Text`
  font-size: 18px;
  font-family:  ${theme().fonts.medium};
  color:  ${theme().colors.tertiary};
  border-top-width: 0.8px;
  border-top-color: #ededed;
  padding: 16px;
  text-transform: uppercase;
`;

export const PriceWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-top: auto;
`;

export const RiskedPrice = styled.Text`
  font-size: 16px;
  font-family: ${theme().fonts.regular};
  color: ${theme().colors.gray};
  text-decoration: line-through;
`;

export const FinalProductPrice = styled.Text<{ isOffer?: boolean }>`
  font-size: 22px;
  font-family: ${theme().fonts.bold};
  color: ${({ isOffer }) =>
    isOffer ? theme().colors.primary : theme().colors.black};
  margin-left: auto;
`;

export const DescriptionBox = styled.View`
  padding: 16px;
  border-width: 0.8px;
  border-color: #ededed;
`;

export const DescriptionText = styled.Text`
  font-size: 16px;
  color: #000000;
  font-family: ${theme().fonts.regular};
`;
export const ContainerMessage = styled.View<{color: string}>`
	position: absolute;
	padding: 5px;
	text-align: center;
  background-color: ${({ color }) => theme(color).colors.primary};
	top: 30px;
	width: 50px;
	border-end-end-radius: 7px;
	border-start-end-radius: 7px;
`;
export const Message = styled.Text`
	color: ${theme().colors.white};
	font-size: 10px;
	font-family: ${theme().fonts.bold};
`;
export const Space = styled.View`
  height: 100px;
`;

export const ButtonsContainer = styled.View`
  padding-bottom: 15px;
  padding-top: 15px;
  padding-horizontal: 20px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme().colors.white};
  align-items: center;
  border-top-width: 0.8px;
  border-top-color: #ededed;
  gap: 10px;
`;
export const ContinueBuyingBorder = styled(Button)<{color: string}>`
  width: 100%;
  border-color: ${({ color }) => theme(color).colors.primary};
`;
export const ContinueBuying = styled(Button)<{color: string}>`
  background-color: ${({ color }) => theme(color).colors.primary};
  width: 100%;
`;

export const TextAddToCart = styled.Text`
  color: ${theme().colors.gray};
  font-family: ${theme().fonts.medium};
  font-size: ${ isLargerThan417 ? '14px' : isLargerThan370 ? '12px' : isLessThan320 ? '10px' : '16px' };
`;

export const AreaAddProduct = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
`
export const Overlay = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #000000cc;
`;

export const ContainerWhatsApp = styled.View`
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 15px;
`;

export const TextLinkWhatsApp = styled.Text`
  font-size: 16px;
  font-family: ${theme().fonts.regular};
  color: ${theme().colors.black};
`;
