import { createContext } from 'react';
import { ProductVisibility } from '../providers/custom-provider';

interface CustomProps {
  storeId: string;
  setStore: (value: string | null) => void;
  catalogName: string
  meiId: string,
  meiInfo: {
    phone: string | null,
    instagram: string | null,
    email: string | null,
    address: string | null
    onlinePurchasesEnabled: boolean
    themeColor: string
    productVisibility: ProductVisibility
  }
}

export const CustomContext = createContext<
  CustomProps | undefined
>(undefined);
