import { useQuery } from '@tanstack/react-query';
import api from "../../services/api"

export const KEY_CATEGORY = 'KEY_CATEGORY';

export interface Categorytem {
  _id: string;
  icon: string;
  mei_id: string;
  name: string;
}

interface CategoryProps {
  currentPage: number
  data: Categorytem[]
  pageCount: number
  total: number
}


export function useGetCategory(meiId: string) {
  const getCategoryStore = async () => {
    const { data } = await api.get<CategoryProps>(
      `/category/v1/list/${meiId}?pag=1&limit=200&type=catalogo`
    );

    const filteredCategories = [
      ...data.data.filter(category => category.name === 'TODOS'),
      ...data.data.filter(category => category.name !== 'TODOS')
    ];

    return filteredCategories
  };

  return useQuery({
    queryKey: [KEY_CATEGORY],
    queryFn: getCategoryStore,
  })
}
