import env from "../env";

// PURCHASE
export const ENV = "prod";
export const TIME_TO_CANCEL_PIX_INVOICE = 1000 * 60 * 5; // 5mins
export const TIME_TO_UPDATE_PURCHASE_STATUS = 5 * 1000; // 5 segs
export const DEFAULT_RADIUS_DISTANCE = 500;

export const BASE_URL = ENV == "prod" ? env.REDIRECT_URL : `http://localhost:19006`;
export const SENTRY_DSN = 'https://a655454d39a5424e89e3417eb2c094b5@o4505200927440896.ingest.us.sentry.io/4505563243151360';

export const IMAGE_URL_DEFAULT =  "https://servemei.s3.amazonaws.com/1720049668989%20-%20semimage2.jpeg"

