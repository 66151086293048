import styled from 'styled-components/native';
import { theme } from '../../global/theme';

export const Container = styled.View`
  background: #fff;
  border-radius: 4px;
  width: 85%;
  align-items: flex-start;
`;

export const Content = styled.View`
  padding: 25px 10px;
  align-items: flex-start;
`;

export const Title = styled.Text`
  font-size: 18px;
  font-family: ${theme().fonts.bold};
  margin-bottom: 15px;
`;

export const Description = styled.Text`
  font-size: 16px;
  font-family: ${theme().fonts.bold};
  text-align: left;
`;

export const Actions = styled.View`
  flex-direction: row;
  justify-content: space-between;
  border-top-width: 1px;
  border-top-color: #949494;
  width: 100%;
`;

export const TextButton = styled.Text`
  color: #2081ff;
   font-size: 14px;
   font-family: ${theme().fonts.bold};
`;
export const IconAlert = styled.TouchableOpacity`
  //position: absolute;
  right: 0px;
  top: 15px;
  background-color: red;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
`;
export const IconContaine = styled.View`
  margin-top: 30px;
`;

export const Overlay = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #000000cc;
`;

export const ContainerDescription = styled.View`
`;

export const ContentDescription = styled.TouchableOpacity`
  flex-direction: row;
  gap: 10px;
  align-items: center;
  display: flex;
  padding: 10px;
`;
export const CloseIcon = styled.Image`
  width: 14px;
  height: 14px;
`
export const ModalAreaHeader = styled.View`
  width: 100%;
  height: 44px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  align-items: flex-end;
  justify-content: center;
`
export const IconCloseModal = styled.TouchableOpacity<{color: string}>`
  width: 37px;
  height: 37px;
  align-items: center;
  justify-content: center;
  margin: 0px 8px 0px 0px;
  border:  ${({ color }) => theme().colors.border};
  border-width: 2px;
  border-radius: 10px;
`