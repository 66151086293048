import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useWindowDimensions, View, StyleSheet, FlatList, Image, Linking, ActivityIndicator } from "react-native";
import { Header } from "../../components/Header";
import { Product } from "../../components/Product";
import { useCart } from "../../hooks/use-cart";
import { NavigationProp, RouteProp, useNavigation } from "@react-navigation/native";
import { ModalProductDetails } from "../../components/ProductDetails";

import Whatsapp from "../../../assets/whatsapp-icon.png";

import { Ionicons } from '@expo/vector-icons';

import {
	Container,
	Content,
	CategoriesContainer,
	CategoriesContainerContent,
	CategoriesContent,
	TextCategories,
	Products,
	ButtonContainer,
	TextLoadingCategory,
	EmptyStore,
	EmptyMessage,
	ContainerIcons,
	TextContacts,
	ButtonContacts,
	ContainerContactAndCart
} from "./styles";

import { useStore } from "../../hooks/use-store";
import { ProductItem, useGetProducts } from "../../store/category-product";
import { useGetCategory } from "../../store/category";
import { Searchbar, Badge } from "react-native-paper";
import { theme } from "../../global/theme";
import { useDebouncedCallback } from "use-debounce";
import ModalContacts from "../../components/ModalContacts";

interface Category {
	id: number;
	name: string;
}

interface RouteParams {
	key: string;
	name: string;
	params: PropsCategory;
	path: any;
}

interface PropsCategory {
	params: string
}

type Navigation = NavigationProp<any>;

type CategoryRouteProp = RouteProp<Record<string, RouteParams>, 'Category'>;


const Category: React.FC<{ route?: CategoryRouteProp }> = ({ route }) => {
	const [categoryName, setCategoryName] = useState("TODOS");
	const [productSelected, setProductSelected] = useState<ProductItem | null>(null)
	const [numColumns, setNumColumns] = useState(2);
	const dimensions = useWindowDimensions()
	const [categoryId, setCategoryId] = useState<string | undefined>(undefined);
	const [searchQuery, setSearchQuery] = useState("");
	const [searchName, setSearcName] = useState("");

	const flatListRef = useRef(null);
	const navigation = useNavigation<Navigation>();

	const [isOpen, setIsOpen] = useState(false)

	const cart = useCart();
	const cartItems = useMemo(() => cart.getCart(), [cart]);
	const paramStore = useStore();
	const itemsCount = useCart();

	const {
		data: productCategory,
		fetchNextPage: fetchNextPageProduct,
		hasNextPage: hasNextPageProduct,
		isInitialLoading: isInitialLoadingProduct,
		isFetching,
	} = useGetProducts(paramStore.meiId, categoryId, searchName);

	const {
		data: categoryData,
	} = useGetCategory(paramStore.meiId)

	const flattenedProducts = useMemo(() => {
		return productCategory?.pages?.flatMap((page) => page?.data) ?? [];
	}, [productCategory, categoryName]);


	const refreshCategories = async (name: string) => {
		setCategoryName(name)
	};

	const handleChangeQuantity = (product: ProductItem, qnty: number) => {
		const cartItem = cartItems.find((item) => item._id === product._id);
		const actualQnty = !cartItem ? 1 : qnty - cartItem.quantity;
		cart.updateCartItem(product, actualQnty, false, false, false);
	};

	useEffect(() => {
		if (categoryData && categoryData.length > 0) {
			setCategoryId(categoryData?.find((item) => item.name === "TODOS")?._id)
		}
	}, [categoryData])

	const renderItemProduct = ({ item }: { item: ProductItem }) => {
		return (
			<Product
				key={item._id}
				title={item?.productName}
				imgUrl={item?.image}
				price_list={item?.price}
				quantity={
					cartItems.find((cartItem) => cartItem._id === item?._id)
						?.quantity || 0
				}
				maxQuantity={item.qtdStock}
				onQuantityChange={(qtd) => handleChangeQuantity(item, qtd)}
				quantityOpen={cart.cartIds.includes(item?._id)}
				onQuantityOpen={() => { }}
				onQuantityPress={() => { }}
				price_final={item?.price_final_in_cents || item?.price}
				style={{
					marginBottom: 20,
					margin: 5
				}}
				testID={item._id}
				onPress={() => setProductSelected(item)}
			/>
		)
	}


	useEffect(() => {
		const productCardWidth = 180;
		const marginOfEachProductCard = 20;
		const cardQuantityPerLine = Math.floor(dimensions.width / (productCardWidth + marginOfEachProductCard))
		setNumColumns(cardQuantityPerLine);
	}, [dimensions])

	const handleIntersection = (entries: IntersectionObserverEntry[]) => {
		const entry = entries[0];
		if (entry.isIntersecting && hasNextPageProduct) {
			fetchNextPageProduct();
		}
	};

	useEffect(() => {
		const observer = new IntersectionObserver(handleIntersection, { threshold: 0.1 });

		if (flatListRef.current) {
			observer.observe(flatListRef.current);
		}

		return () => {
			if (flatListRef.current) {
				observer.unobserve(flatListRef.current);
			}
		};
	}, [flatListRef.current]);

	const handleSubmit = async (term: string) => {
		setSearcName(term)
	};

	async function redirectToStore() {
		let supportURL = `https://api.whatsapp.com/send?phone=+55${paramStore?.meiInfo?.phone}&text=Olá preciso de ajuda.`;
		await Linking.openURL(supportURL);
	}

	const searchForProductLate = useDebouncedCallback((text: string) => handleSubmit(searchQuery), 800);

	const keyExtractor = useCallback((item: any, i: number) => `${i}-${item?.id}`, []);

	return (
		<>
			<Header />
			<Searchbar
				placeholder="Buscar produto"
				onChangeText={(text) => {
					setSearchQuery(text);
					searchForProductLate(text)
				}}
				value={searchQuery}
				style={{ backgroundColor: "#99999911", borderRadius: 0, color: "#000" }}
				placeholderTextColor={"#999999"}
				iconColor={"#999999"}
				inputStyle={{ color: theme().colors.black }}
			/>

			<ContainerIcons>
				{paramStore?.meiInfo?.phone || paramStore?.meiInfo?.instagram?.replace("@", "") || paramStore?.meiInfo?.email ? (
					<ButtonContacts onPress={() => setIsOpen(true)}>
						<Ionicons name={"chatbubble-ellipses-outline"} size={28} color={theme().colors.black} />
						<TextContacts>Contatos</TextContacts>
					</ButtonContacts>
				) : <ButtonContacts />}
				<ContainerContactAndCart>
					{paramStore?.meiInfo?.phone && (
						<ButtonContacts onPress={redirectToStore}>
							<Image source={Whatsapp} style={{ width: 25, height: 25 }} />
						</ButtonContacts>
					)}
					{paramStore?.meiInfo?.onlinePurchasesEnabled && (
						<ButtonContacts onPress={() => navigation.navigate("Cart")}>
							<Ionicons name={"cart-outline"} size={28} color={theme().colors.black} />
							{itemsCount.itemsTotal > 0 && (
								<Badge style={{
									backgroundColor: theme().colors.red,
									color: theme().colors.white,
									fontWeight: "bold",
									position: "absolute",
									top: -10,
									right: -10
								}}>{itemsCount.itemsTotal}</Badge>
							)}
						</ButtonContacts>
					)}
				</ContainerContactAndCart>
			</ContainerIcons>

			<Container>
				<Content>
					<CategoriesContainer showsHorizontalScrollIndicator={false}>
						<CategoriesContainerContent
							horizontal
							showsHorizontalScrollIndicator={false}
						>
							{categoryData?.length && categoryData?.map((item) => {
								return (
									<CategoriesContent
										key={item._id}
										color={paramStore.meiInfo.themeColor}
										selected={item.name == categoryName}
										onPress={() => {
											setCategoryId(item._id);
											refreshCategories(item.name)
										}
										}
									>
										<TextCategories
											selected={item.name == categoryName}
											color={paramStore.meiInfo.themeColor}
										>
											{item.name}
										</TextCategories>
									</CategoriesContent>
								);
							})}
						</CategoriesContainerContent>

						{flattenedProducts?.length <= 0 && !isInitialLoadingProduct && (
							<EmptyStore>
								<EmptyMessage>
									Nenhum produto disponível.
								</EmptyMessage>
							</EmptyStore>
						)}

						{flattenedProducts?.length > 0 && (
							<Products>
								<FlatList
									data={flattenedProducts || []}
									key={numColumns}
									extraData={cart?.cartIds}
									keyExtractor={keyExtractor}
									renderItem={renderItemProduct}
									numColumns={numColumns < 2 ? 2 : numColumns > 4 ? 4 : numColumns}
									showsVerticalScrollIndicator={false}
									ListFooterComponent={() => (
										<ButtonContainer ref={flatListRef}>
											{isInitialLoadingProduct && (
												<TextLoadingCategory>Carregando dados</TextLoadingCategory>
											)}
										</ButtonContainer>
									)}
								/>
							</Products>
						)}
						{isFetching && !isInitialLoadingProduct && (
							<ButtonContainer ref={flatListRef}>
								<TextLoadingCategory>
									Carregando dados
								</TextLoadingCategory>
							</ButtonContainer>
						)}
					</CategoriesContainer>

				</Content>
			</Container>
			{isInitialLoadingProduct && (
				<View style={styles.container}>
					<ActivityIndicator size="large" color={paramStore.meiInfo.themeColor} />
				</View>
			)}

			{productSelected && (
				<ModalProductDetails product={productSelected} setProductSelected={setProductSelected} />
			)}

			<ModalContacts isOpen={isOpen} setIsOpen={setIsOpen} />
		</>
	);
};

export default Category;


const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: "rgba(0,0,0,0.0)"
	},
});
