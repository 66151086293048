import { useInfiniteQuery } from "@tanstack/react-query";
import api from "../../services/api";

export const PRODUCTS_STORES = 'PRODUCTS_STORES';

export interface ProductItem {
  _id: string;
  barcode: string;
  category: string;
  cfop: string;
  createAt: string; // Considere mudar para tipo Date se for manipular datas
  description: string;
  etiqueta: string;
  habilityFiscal: boolean;
  idFiscal: number;
  image: string;
  mei_id: string;
  ncm: string;
  price_final_in_cents: number
  price: number;
  priceCost: number;
  productName: string;
  qtdStock: number;
  seq: number;
  unidadeSigla: 'Kg' | 'Metro' | 'Un';
  updateAt: string; // Considere mudar para tipo Date se for manipular datas
}

interface ProductsProps {
  currentPage: number
  data: ProductItem[]
  pageCount: number
  total: number
}

export function useGetProducts(meiId: string, categoryId: string | undefined, searchQuery: string) {
  const getProducts = async (page = 1) => {
    const { data } = await api.get<ProductsProps>(
      `/stock/productByCategory/${meiId}?page=${page}&type=catalogo${categoryId ? `&categoryId=${categoryId}` : ""}${searchQuery ? `&name=${searchQuery}` : ""}`);

    return {
      data: data.data || [],
      nextPage: page + 1,
    };
  };

  return useInfiniteQuery({
    queryKey: [PRODUCTS_STORES, categoryId, searchQuery],
    queryFn: ({ pageParam }) => getProducts(pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.data.length < 10) return undefined;
      return lastPage.nextPage;
    },
  });
}
