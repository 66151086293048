import React, { useMemo, useState } from 'react'
import { Modal as ReactNativeModal } from 'react-native';
import { Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';

import {
  Overlay,
  ModalArea,
  ModalAreaHeader,
  IconCloseModal,
  CloseIcon,
  ModalContent,
  ModalLabelInput,
  InputContentMail,
  InputLabel,
  InputLabelMandatory,
  InputTextArea,
  ButtonSend,
  ButtonSendText,
  ErrorMessage,
  ItemsScroll,
  ButtonBack,
  ButtonBackText,
  Divider,
  ContainerFlex,
  Title,
  ContainerCards,
  CardPayment,
  CardPaymentIcon,
  CardPaymentTitle,
} from './styles';

import { theme } from '../../global/theme';
import { formatCPF, isValidCPF, phoneFormat } from '../../utils/formatCpf';
import { NumberToCurrency } from '../../utils/numberToCurrency';
import { useCart } from '../../hooks/use-cart';
import { formatCEP, getOnlyNumbers } from '../../utils/format';
import { AddressState } from '../../utils/validState';
import { CreateOrderDto, useSendPaymentsMutation } from '../../store/salles/createPayment';
import { getAddressByZipCode } from '../../services/viaCep';
import ModalAlert from '../ModalAlert';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useStore } from '../../hooks/use-store';

type AddressValidation = {
  name: string;
  cpf: string;
  email: string;
  phone: string;
  cep: string;
  city: string;
  state: string;
  logradouro: string;
  number: string;
  district: string;
  complement: string;
};

interface FormValues {
  name: string;
  cpf: string;
  email: string;
  phone: string;
  cep: string;
  city: string;
  state: string;
  logradouro: string;
  number: string;
  district: string;
  complement: string;
}

const errors: FormikErrors<FormValues> = {
  email: "Campo obrigatório",
  logradouro: "Campo obrigatório",
  name: "Campo obrigatório",
  number: "Campo obrigatório",
  phone: "Campo obrigatório",
  state: "Campo obrigatório"
};

const PaymentMethod = [
  {
    id: 5,
    name: 'Dinheiro',
    value: "Dinheiro",
    icon: require('../../../assets/dollar.svg')
  },
  {
    id: 1,
    name: 'Cartão de Crédito',
    value: "Credito",
    icon: require('../../../assets/credit_card.svg')
  },
  {
    id: 2,
    name: 'Cartão de Débito',
    value: "Debito",
    icon: require('../../../assets/credit_card.svg')
  },
  {
    id: 4,
    name: 'PIX',
    value: "Pix",
    icon: require('../../assets/pix.svg')
  },
]

type Navigation = NavigationProp<any>;

interface SendReceiptProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

declare module 'yup' {
  interface StringSchema {
    isValidCpf(errorMessage: string): StringSchema<string>;
  }
}

const isValidCep = (text: string) => {
  return getOnlyNumbers(text).length === 8;
};

const isValidState = (state: string) => {
  return AddressState.some((item) => item.value === state);
};

Yup.addMethod(Yup.string, 'isValidCpf', function (errorMessage: string) {
  return this.test('test-if-is-valid-cpf', errorMessage, function (cpf) {
    if (!cpf || cpf.trim() === '') {
      return false;
    }
    return isValidCPF(cpf);
  });
});

Yup.addMethod(Yup.string, 'isValidCep', function (errorMessage) {
  return this.test('test-if-is-valid-cep', errorMessage, function (state) {
    return state === undefined ? false : isValidCep(state);
  });
});

Yup.addMethod(Yup.string, 'isValidState', function (errorMessage) {
  return this.test('test-if-is-valid-state', errorMessage, function (state) {
    return state === undefined ? false : isValidState(state);
  });
});


const CpfSchema = Yup.object({
  cpf: Yup.string().isValidCpf('CPF inválido'),
  name: Yup.string().required('Campo obrigatório').min(3, "Nome muito curto"),
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  phone: Yup.string().required('Campo obrigatório').min(14, "Telefone inválido").max(15, "Telefone inválido"),
  // @ts-ignore
  cep: Yup.string().required('Campo obrigatório').isValidCep('CEP inválido'),
  city: Yup.string().min(4, 'Cidade inválida').required('Campo obrigatório'),
  state: Yup.string()
    .min(2, 'UF inválido')
    .max(2, 'UF inválido')
    .required('Campo obrigatório')
    // @ts-ignore
    .isValidState('Estado inválido'),
  logradouro: Yup.string()
    .min(4, 'Endereço residencial inválido')
    .required('Campo obrigatório'),
  district: Yup.string()
    .min(2, 'Bairro inválido')
    .required('Campo obrigatório'),
  number: Yup.string().required('Campo obrigatório'),
})

const ModalFinish: React.FC<SendReceiptProps> = ({ setOpen }) => {

  const [isFocused, setIsFocused] = useState({
    name: false,
    cpf: false,
    email: false,
    phone: false,
    cep: false,
    city: false,
    state: false,
    logradouro: false,
    number: false,
    district: false,
    complement: false,
  });
  const myCart = useCart();
  const paramStore = useStore();
  const navigation = useNavigation<Navigation>();
  const cartItems = useMemo(() => myCart.getCart(), [myCart]);
  const [isVerifyAddress, setIsVerifyAddress] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [codigoIbge, setCodigoIbge] = useState("")
  //
  const [open, setIsOpen] = useState(false)
  const [isErroOpen, setIsErroOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  //
  const [formSend, setFormSend] = useState<CreateOrderDto | null>()

  const { mutateAsync, isLoading } = useSendPaymentsMutation()
  //
  const [step, setStep] = useState(1)
  //
  const [methodSelected, setMethodSelected] = useState("Dinheiro")

  const finalTotal = React.useMemo(() => {
    return cartItems.reduce((total, item) => {
      return total + (item?.price_final_in_cents || item.price) * item.quantity;
    }, 0);
  }, [cartItems]);

  const handleSendSale = async (values: AddressValidation) => {
    if (!isVerifyAddress) return

    const form = {
      payment: [
        {
          paid: finalTotal,
          type: methodSelected
        }
      ],
      items: cartItems.map((item) => {
        return {
          "product_id": item._id,
          "quantity": item.quantity
        }
      }),
      client: {
        name: values.name,
        cpf: values.cpf,
        email: values.email,
        phone: values.phone,
        cep: values.cep,
        city: values.city,
        state: values.state,
        logradouro: values.logradouro,
        number: values.number,
        district: values.district,
        complement: values.complement,
        codigoIbge
      },
      totalFormated: NumberToCurrency(finalTotal),
      totalUnformated: finalTotal,
    }

    setFormSend(form)
    setConfirmOpen(true)
  }

  const actionOk = () => {
    setOpen(false)
    setIsOpen(false)
    myCart.clearCart()
    navigation.navigate("Loja")
  }

  const actionConfirm = async () => {
    setConfirmOpen(false)

    if (!formSend) {
      setIsErroOpen(true)
      return
    }

    try {
      await mutateAsync(formSend)
      setIsOpen(true)
    } catch (error) {
      setIsErroOpen(true)
    }
  }

  const navigationBack = () => {
    if (step === 1) {
      setOpen(false)
    } else {
      setStep(step - 1)
    }
  }

  return (
    <ReactNativeModal transparent={true} visible animationType="slide">
      <Overlay>
        <ModalArea>
          <ModalAreaHeader>
            <IconCloseModal
              color={paramStore.meiInfo.themeColor}
              onPress={() => {
                setOpen(false)
              }}>
              <CloseIcon source={require('../../../assets/close.svg')} />
            </IconCloseModal>
          </ModalAreaHeader>
          <ModalContent>
            <ModalLabelInput>
              {"Para concluir seu pedido, \nprecisamos das suas informações."}
            </ModalLabelInput>
            <ItemsScroll showsVerticalScrollIndicator={false}>
              <Formik
                initialValues={{
                  name: '',
                  cpf: '',
                  email: '',
                  phone: '',
                  cep: '',
                  city: '',
                  state: '',
                  logradouro: '',
                  number: '',
                  district: '',
                  complement: '',
                }}
                validationSchema={CpfSchema}
                onSubmit={handleSendSale}
              >
                {({ handleChange, handleBlur, handleSubmit, values, errors, setValues, setErrors }) => {
                  const getAddress = async (value: string) => {
                    const zipcode = getOnlyNumbers(value);
                    if (zipcode.length === 8) {
                      setLoading(true);
                      getAddressByZipCode(zipcode)
                        .then((address) => {
                          if (address.data?.erro) {
                            setErrors({
                              ...errors,
                              cep: 'CEP inválido'
                            })
                            setIsVerifyAddress(false);
                            return;
                          }
                          if (address.data) {
                            setValues({
                              ...values,
                              cep: formatCEP(value),
                              city: address.data.localidade,
                              state: address.data.uf,
                              logradouro: address.data.logradouro,
                              district: address.data.bairro,
                              number: "0"
                            })
                            setCodigoIbge(address.data?.ibge || "")
                            setIsVerifyAddress(true);
                            setLoading(false);
                          }
                        })
                        .catch(() => {
                          setErrors({
                            cep: 'CEP inválido'
                          })
                          setIsVerifyAddress(false);
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    }
                  }

                  const stepNavigation = () => {
                    const fieldsToCheckStep01 = ['email', 'cpf', 'name', 'phone'];
                    const fieldsToCheckStep02 = ['cep', 'city', 'district', 'logradouro', "number", "state"];
                    const allFieldsPresent01 = fieldsToCheckStep01.filter(field => errors && (errors as any)[field] !== undefined);
                    const allFieldsPresent02 = fieldsToCheckStep02.filter(field => errors && (errors as any)[field] !== undefined);

                    if (step === 1 && allFieldsPresent01.length <= 0) {
                      setStep(step + 1)
                      return
                    }
                    if (step === 2 && allFieldsPresent02.length <= 0) {
                      if (!isVerifyAddress) {
                        setErrors({
                          ...errors,
                          cep: 'CEP inválido'
                        })
                        return
                      }

                      setStep(step + 1)
                      return
                    }

                    handleSubmit()
                  }

                  React.useEffect(() => {
                    if (step === 1 || step === 2) {
                      handleSubmit()
                    }
                  }, [])

                  return (
                    <>
                      {step === 1 && (
                        <>
                          <InputContentMail color={paramStore.meiInfo.themeColor}
                            isFocused={isFocused.name} style={{ borderColor: errors.name && `${theme().colors.red}` }}>
                            <InputLabel>
                              Nome completo
                              <InputLabelMandatory color={paramStore.meiInfo.themeColor}>*</InputLabelMandatory>
                            </InputLabel>
                            <InputTextArea
                              onFocus={() => setIsFocused({
                                ...isFocused,
                                name: true
                              })}
                              onBlur={() => {
                                handleBlur('name')
                                setIsFocused({
                                  ...isFocused,
                                  name: false
                                })
                              }}
                              onChangeText={(value: string) => {
                                handleChange('name')(value?.toUpperCase());
                              }}
                              value={values.name}
                            />
                            {errors.name &&
                              <ErrorMessage>
                                {errors.name}
                              </ErrorMessage>
                            }
                          </InputContentMail>
                          <InputContentMail color={paramStore.meiInfo.themeColor}
                            isFocused={isFocused.cpf} style={{ borderColor: errors.cpf && `${theme().colors.red}` }}>
                            <InputLabel>
                              CPF
                              <InputLabelMandatory color={paramStore.meiInfo.themeColor}>*</InputLabelMandatory>
                            </InputLabel>
                            <InputTextArea
                              onFocus={() => setIsFocused({
                                ...isFocused,
                                cpf: true
                              })}
                              onBlur={() => {
                                handleBlur('cpf')
                                setIsFocused({
                                  ...isFocused,
                                  cpf: false
                                })
                              }}
                              onChangeText={(value: string) => {
                                const formattedValue = formatCPF(value);
                                handleChange('cpf')(formattedValue);
                              }}
                              value={values.cpf}
                            />
                            {errors.cpf &&
                              <ErrorMessage>
                                {errors.cpf}
                              </ErrorMessage>
                            }
                          </InputContentMail>
                          <InputContentMail color={paramStore.meiInfo.themeColor}
                            isFocused={isFocused.email} style={{ borderColor: errors.email && `${theme().colors.red}` }}>
                            <InputLabel>
                              E-mail
                              <InputLabelMandatory color={paramStore.meiInfo.themeColor}>*</InputLabelMandatory>
                            </InputLabel>
                            <InputTextArea
                              onFocus={() => setIsFocused({
                                ...isFocused,
                                email: true
                              })}
                              onBlur={() => {
                                handleBlur('email')
                                setIsFocused({
                                  ...isFocused,
                                  email: false
                                })
                              }}
                              onChangeText={(value: string) => {
                                handleChange('email')(value);
                              }}
                              value={values.email}
                            />
                            {errors.email &&
                              <ErrorMessage>
                                {errors.email}
                              </ErrorMessage>
                            }
                          </InputContentMail>
                          <InputContentMail color={paramStore.meiInfo.themeColor}
                            isFocused={isFocused.phone} style={{ borderColor: errors.phone && `${theme().colors.red}` }}>
                            <InputLabel>
                              Telefone
                              <InputLabelMandatory color={paramStore.meiInfo.themeColor}>*</InputLabelMandatory>
                            </InputLabel>
                            <InputTextArea
                              onFocus={() => setIsFocused({
                                ...isFocused,
                                phone: true
                              })}
                              onBlur={() => {
                                handleBlur('phone')
                                setIsFocused({
                                  ...isFocused,
                                  phone: false
                                })
                              }}
                              onChangeText={(value: string) => {
                                const formattedValue = phoneFormat(value);
                                handleChange('phone')(formattedValue);
                              }}
                              value={values.phone}
                              maxLength={15}
                            />
                            {errors.phone &&
                              <ErrorMessage>
                                {errors.phone}
                              </ErrorMessage>
                            }
                          </InputContentMail>
                        </>
                      )}
                      {step === 2 && (
                        <>
                          <Title>
                            Qual seu endereço ?
                          </Title>
                          <ContainerFlex>
                            <InputContentMail color={paramStore.meiInfo.themeColor}
                              isFocused={isFocused.cep} style={{ borderColor: errors.cep && `${theme().colors.red}`, width: "49.3%" }}>
                              <InputLabel>
                                CEP
                                <InputLabelMandatory color={paramStore.meiInfo.themeColor}>*</InputLabelMandatory>
                              </InputLabel>
                              <InputTextArea
                                onFocus={() => setIsFocused({
                                  ...isFocused,
                                  cep: true
                                })}
                                onBlur={() => {
                                  handleBlur('cep')
                                  setIsFocused({
                                    ...isFocused,
                                    cep: false
                                  })
                                }}
                                onChangeText={(value: string) => {
                                  const cepFormat = formatCEP(value)
                                  getAddress(cepFormat)
                                  handleChange('cep')(cepFormat);
                                }}
                                value={values.cep}
                                maxLength={9}
                              />
                              {errors.cep &&
                                <ErrorMessage>
                                  {errors.cep}
                                </ErrorMessage>
                              }
                            </InputContentMail>
                            <InputContentMail color={paramStore.meiInfo.themeColor}
                              isFocused={isFocused.logradouro} style={{ borderColor: errors.logradouro && `${theme().colors.red}`, width: "49.3%" }}>
                              <InputLabel>
                                Endereço residencial
                                <InputLabelMandatory color={paramStore.meiInfo.themeColor}>*</InputLabelMandatory>
                              </InputLabel>
                              <InputTextArea
                                onFocus={() => setIsFocused({
                                  ...isFocused,
                                  logradouro: true
                                })}
                                onBlur={() => {
                                  handleBlur('logradouro')
                                  setIsFocused({
                                    ...isFocused,
                                    logradouro: false
                                  })
                                }}
                                onChangeText={(value: string) => {
                                  handleChange('logradouro')(value);
                                }}
                                value={values.logradouro}
                                maxLength={15}
                              />
                              {errors.logradouro &&
                                <ErrorMessage>
                                  {errors.logradouro}
                                </ErrorMessage>
                              }
                            </InputContentMail>
                          </ContainerFlex>
                          <ContainerFlex>
                            <InputContentMail color={paramStore.meiInfo.themeColor}
                              isFocused={isFocused.city} style={{ borderColor: errors.city && `${theme().colors.red}`, width: "49.3%" }}>
                              <InputLabel>
                                Cidade
                                <InputLabelMandatory color={paramStore.meiInfo.themeColor}>*</InputLabelMandatory>
                              </InputLabel>
                              <InputTextArea
                                onFocus={() => setIsFocused({
                                  ...isFocused,
                                  city: true
                                })}
                                onBlur={() => {
                                  handleBlur('city')
                                  setIsFocused({
                                    ...isFocused,
                                    city: false
                                  })
                                }}
                                onChangeText={(value: string) => {
                                  handleChange('city')(value);
                                }}
                                value={values.city}
                              />
                              {errors.city &&
                                <ErrorMessage>
                                  {errors.city}
                                </ErrorMessage>
                              }
                            </InputContentMail>
                            <InputContentMail color={paramStore.meiInfo.themeColor}
                              isFocused={isFocused.state} style={{ borderColor: errors.state && `${theme().colors.red}`, width: "49.3%" }}>
                              <InputLabel>
                                UF
                                <InputLabelMandatory color={paramStore.meiInfo.themeColor}>*</InputLabelMandatory>
                              </InputLabel>
                              <InputTextArea
                                onFocus={() => setIsFocused({
                                  ...isFocused,
                                  state: true
                                })}
                                onBlur={() => {
                                  handleBlur('state')
                                  setIsFocused({
                                    ...isFocused,
                                    state: false
                                  })
                                }}
                                onChangeText={(value: string) => {
                                  handleChange('state')(value?.toUpperCase());
                                }}
                                value={values.state}
                                maxLength={2}
                              />
                              {errors.state &&
                                <ErrorMessage>
                                  {errors.state}
                                </ErrorMessage>
                              }
                            </InputContentMail>
                          </ContainerFlex>
                          <InputContentMail color={paramStore.meiInfo.themeColor}
                            isFocused={isFocused.district} style={{ borderColor: errors.district && `${theme().colors.red}` }}>
                            <InputLabel>
                              Bairro
                              <InputLabelMandatory color={paramStore.meiInfo.themeColor}>*</InputLabelMandatory>
                            </InputLabel>
                            <InputTextArea
                              onFocus={() => setIsFocused({
                                ...isFocused,
                                district: true
                              })}
                              onBlur={() => {
                                handleBlur('district')
                                setIsFocused({
                                  ...isFocused,
                                  district: false
                                })
                              }}
                              onChangeText={(value: string) => {
                                handleChange('district')(value);
                              }}
                              value={values.district}
                            />
                            {errors.district &&
                              <ErrorMessage>
                                {errors.district}
                              </ErrorMessage>
                            }
                          </InputContentMail>
                          <ContainerFlex>
                            <InputContentMail color={paramStore.meiInfo.themeColor}
                              isFocused={isFocused.number} style={{ borderColor: errors.number && `${theme().colors.red}`, width: "49.3%" }}>
                              <InputLabel>
                                Número
                                <InputLabelMandatory color={paramStore.meiInfo.themeColor}>*</InputLabelMandatory>
                              </InputLabel>
                              <InputTextArea
                                onFocus={() => setIsFocused({
                                  ...isFocused,
                                  number: true
                                })}
                                onBlur={() => {
                                  handleBlur('number')
                                  setIsFocused({
                                    ...isFocused,
                                    number: false
                                  })
                                }}
                                onChangeText={(value: string) => {
                                  handleChange('number')(value);
                                }}
                                value={values.number}
                              />
                              {errors.number &&
                                <ErrorMessage>
                                  {errors.number}
                                </ErrorMessage>
                              }
                            </InputContentMail>
                            <InputContentMail color={paramStore.meiInfo.themeColor}
                              isFocused={isFocused.complement} style={{ borderColor: errors.complement && `${theme().colors.red}`, width: "49.3%" }}>
                              <InputLabel>
                                Complemento
                                <InputLabelMandatory color={paramStore.meiInfo.themeColor}>*</InputLabelMandatory>
                              </InputLabel>
                              <InputTextArea
                                onFocus={() => setIsFocused({
                                  ...isFocused,
                                  complement: true
                                })}
                                onBlur={() => {
                                  handleBlur('complement')
                                  setIsFocused({
                                    ...isFocused,
                                    complement: false
                                  })
                                }}
                                onChangeText={(value: string) => {
                                  handleChange('complement')(value);
                                }}
                                value={values.complement}
                                maxLength={15}
                              />
                              {errors.complement &&
                                <ErrorMessage>
                                  {errors.complement}
                                </ErrorMessage>
                              }
                            </InputContentMail>
                          </ContainerFlex>
                        </>
                      )}

                      {step === 3 && (
                        <>
                          <Title>
                            Como deseja Pagar ?
                          </Title>
                          <ContainerCards>
                            {PaymentMethod.map((item, index) => (
                              <CardPayment
                                disabled={isLoading}
                                key={index}
                                color={paramStore.meiInfo.themeColor}
                                methodSelected={methodSelected === item.value} onPress={() => setMethodSelected(item.value)}>
                                <CardPaymentIcon source={item.icon} />
                                <CardPaymentTitle methodSelected={methodSelected === item.value}>
                                  {item.name}
                                </CardPaymentTitle>
                              </CardPayment>
                            ))}
                          </ContainerCards>
                        </>
                      )}

                      <ButtonSend onPress={stepNavigation}
                        color={paramStore.meiInfo.themeColor}
                        loading={isLoading}>
                        {step === 1 && "Proxímo"}
                        {step === 2 && "Proxímo"}
                        {step === 3 && `Finalizar ${NumberToCurrency(finalTotal)}`}
                      </ButtonSend>
                      <ButtonBack
                        textStyle={{ color: theme(paramStore.meiInfo.themeColor).colors.primary, opacity: 0.6 }}
                        type="primary_ghost"
                        onPress={navigationBack}
                        color={paramStore.meiInfo.themeColor}>
                        Voltar
                      </ButtonBack>
                    </>
                  )
                }}
              </Formik>
            </ItemsScroll>
          </ModalContent>
        </ModalArea>
        <ModalAlert
          title="Pedido realizado com sucesso!"
          description="Seu pedido foi realizado com sucesso, em breve você receberá uma mensagem com as informações do seu pedido."
          action={actionOk}
          setIsOpen={setIsOpen}
          isOpen={open}
          oneButton={true}
          onOkButtonText={"OK"}
          type="success"
        />
        <ModalAlert
          title="Erro ao realizar pedido"
          description="Ocorreu um erro ao realizar seu pedido, tente novamente."
          action={() => setIsErroOpen(false)}
          setIsOpen={setIsErroOpen}
          isOpen={isErroOpen}
          oneButton={true}
          onOkButtonText={"OK"}
          type="erro"
        />
        <ModalAlert
          title="Confirmação de Pedido"
          description="Você está prestes a realizar seu pedido. Deseja continuar?"
          action={actionConfirm}
          setIsOpen={setConfirmOpen}
          isOpen={confirmOpen}
          oneButton={false}
          onOkButtonText={"CONFIRMAR"}
          onCancelButtonText={"CANCELAR"} // Adiciona um botão de cancelar para oferecer uma opção ao usuário
          type="info"
        />
      </Overlay>
    </ReactNativeModal>
  )
}

export default ModalFinish