import React, { useState, useMemo, useRef } from "react";
import { NavigationProp, useFocusEffect, useNavigation } from "@react-navigation/native";
import { CartItem } from "../../components/CartItem";

import { FontAwesome } from '@expo/vector-icons';

import {
  Container,
  Content,
  ContainerContentEmptyCart,
  ContentEmptyCart,
  EmptyCartContainer,
  EmptyCartIcon,
  NoItemsText,
  ConfirmButton,
  TextConfirmButton,
  TotalContainer,
  TotalContainerContent,
  TotalContainerContentTextValue,
  TotalContainerContentValue,
  ItemsScroll,
  CartContentContainer,
  BtnContainer,
  ContentButtonProducts,
  LineContainer,
  LineText,
  ChangeButton,
  TextChange,
  StoreAreaInfo,
  StoreLogo,
  StoreAreaTitle,
  StoreTitle,
  StoreAddress,
  PurchaseDetails,
  BtnArea,
  CardItemsArea,
  LineArea,
  PaymentLineContainer,
  PaymentDetailsContainer,
  PaymentDetailsTitle,
  PaymentDetailsDescription,
  PaymentChangeText,
  NoteIcon,
  PaymentTitle,
  IconContaine,
} from "./styles";
import { useCart } from "../../hooks/use-cart";
import { NumberToCurrency } from "../../utils/numberToCurrency";

import { Header } from "../../components/Header";
import ModalFinish from "../../components/ModalFinish";
import { ProductItem } from "../../store/category-product";
import { ModalProductDetails } from "../../components/ProductDetails";
import { useStore } from "../../hooks/use-store";
import { theme } from "../../global/theme";

type Navigation = NavigationProp<any>;

const Cart: React.FC = () => {
  const myCart = useCart();
  const cartItems = useMemo(() => myCart.getCart(), [myCart]);
  const navigation = useNavigation<Navigation>();
  const paramStore = useStore();

  const listItemRef = useRef<any>(null)
  const [productSelected, setProductSelected] = useState<ProductItem | null>(null)
  const [showModalFinish, setShowModalFinish] = useState(false);


  const handleNavigateToStore = () => {
    navigation.navigate("Loja");
  };

  const finalTotal = React.useMemo(() => {
    return cartItems.reduce((total, item) => {
      return total + (item?.price_final_in_cents || item.price) * item.quantity;
    }, 0);
  }, [cartItems]);

  const isActiveFiscal = true

  useFocusEffect(
    React.useCallback(() => {
      const scrollToBottom = () => {
        if (listItemRef?.current && cartItems?.length > 0) {
          listItemRef?.current?.scrollTo({ y: (cartItems.length - 2) * 130, animated: true });
        }
      };

      scrollToBottom();

      return () => { };
    }, [cartItems?.length])
  );

  return (
    <>
      <Header />
      <Container>
        <Content>
          {cartItems.length === 0 ? (
            <EmptyCartContainer>
              <ContainerContentEmptyCart>
                <ContentEmptyCart>
                  <FontAwesome name={"cart-arrow-down"} size={70} color={"#99999988"} />
                  <NoItemsText>
                    {"Nenhum produto aqui. \nQue tal dar uma olhada no catálogo?"}
                  </NoItemsText>
                </ContentEmptyCart>
                <ContentButtonProducts>
                  <ConfirmButton onPress={handleNavigateToStore} color={paramStore.meiInfo.themeColor}>
                    <TextConfirmButton>Acessar catálogo</TextConfirmButton>
                  </ConfirmButton>
                </ContentButtonProducts>
              </ContainerContentEmptyCart>
            </EmptyCartContainer>
          ) : (
            <>
              <CartContentContainer>
                <ItemsScroll ref={listItemRef}>
                  <CardItemsArea>
                    {cartItems.map((item, index) => (
                      <CartItem
                        key={index}
                        item={item}
                        isLast={cartItems.length - 1 === index}
                        setProductSelected={setProductSelected}
                      />
                    ))}
                  </CardItemsArea>

                  <PurchaseDetails padding={isActiveFiscal}>
                    <TotalContainerContent>
                      <TotalContainerContentTextValue isLeft={isActiveFiscal} color={paramStore.meiInfo.themeColor}>
                        Total ({myCart.itemsTotal} un)
                      </TotalContainerContentTextValue>
                    </TotalContainerContent>
                    <TotalContainerContentValue>
                      <TotalContainerContentTextValue isRigth={isActiveFiscal} color={paramStore.meiInfo.themeColor}>
                        {NumberToCurrency(
                          cartItems.reduce(
                            (all, curr) =>
                              all +
                              (curr?.price_final_in_cents || curr.price) * curr.quantity,
                            0
                          )
                        )}
                      </TotalContainerContentTextValue>
                    </TotalContainerContentValue>
                  </PurchaseDetails>
                </ItemsScroll>
                <BtnContainer>
                  <BtnArea>
                    <ConfirmButton
                      color={paramStore.meiInfo.themeColor}
                      onPress={() => setShowModalFinish(true)}
                    >
                      Finalizar {NumberToCurrency(finalTotal)}
                    </ConfirmButton>
                  </BtnArea>
                  <BtnArea>
                    <ChangeButton
                      type="primary_ghost"
                      onPress={handleNavigateToStore}
                      color={paramStore.meiInfo.themeColor}
                      textStyle={{ color: theme(paramStore.meiInfo.themeColor).colors.primary, opacity: 0.6 }}
                    >
                      Voltar
                    </ChangeButton>
                  </BtnArea>
                </BtnContainer>
              </CartContentContainer>
            </>
          )}
        </Content>
      </Container>

      {showModalFinish && (
        <ModalFinish
          setOpen={setShowModalFinish}
        />
      )}

      {productSelected && (
        <ModalProductDetails product={productSelected} setProductSelected={setProductSelected} />
      )}
    </>
  );
};

export default Cart;
