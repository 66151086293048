import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import {
	View,
	Linking,
	StyleSheet,
	ActivityIndicator,
} from "react-native";
import { CustomContext } from "../contexts/custom-context";
import api from "../services/api";
import { Header } from "../components/Header";
import { theme } from "../global/theme";

export type ProductVisibility =
  | 'hide_in_catalog'// Não exibir no catálogo
  | 'show_as_available' // Exibir normalmente
  | 'show_as_unavailable'; // Exibir como indisponível

const EmptyStore = styled.View`
	flex: 1;
	align-items: center;
	justify-content: center;
	margin: 20px;
`;

const EmptyMessage = styled.Text`
	color: ${theme().colors.black};
	font-size: 14px;
	font-family: ${theme().fonts.regular};
`;

export const CustomProvider = ({ children }: React.PropsWithChildren) => {
	const [loading, setLoading] = useState(true);
	const [store, setStore] = useState("");
	const [erro, setErro] = useState(false)
	const [catalogName, setCatalogName] = useState("")
	const [meiId, setMeiId] = useState("")
	const [meiInfo, setMeiInfo] = useState<{
		phone: string | null,
		instagram: string | null,
		email: string | null
		address: string | null
		onlinePurchasesEnabled: boolean
		themeColor: string
		productVisibility: ProductVisibility
	}>({
		phone: null,
		instagram: null,
		email: null,
		address: null,
		onlinePurchasesEnabled: false,
		themeColor: "#0593d2",
		productVisibility: "show_as_available"
	})

	useEffect(() => {
		loginHidden();
	}, []);

	const loginHidden = async () => {
		const urlbase = (await Linking.getInitialURL()) || "";
		const url = new URL(urlbase);
		const storeId = url.searchParams.get("store") as string;

		if (!storeId) {
			setErro(true)
			setLoading(false)
			return
		}

		setStore(storeId);

		api.get(`/catalogSettings/codAcesso/${storeId}`).then((response) => {
			const authToken = `Bearer ${response.data.token}`;
			api.defaults.headers.common["Authorization"] = authToken;
			setMeiId(response.data.data.mei_id)
			setCatalogName(response.data.data.catalogName)
			setMeiInfo({
				phone: response.data?.data?.phone || null,
				instagram: response.data?.data?.instagram || null,
				email: response.data?.data?.email || null,
				address: response.data?.data?.address || null,
				onlinePurchasesEnabled: response.data?.data?.onlinePurchasesEnabled || false,
				themeColor: response.data?.data?.themeColor || "#0593d2",
				productVisibility: response.data?.data?.productVisibility || "show_as_available"
			})
			setErro(false)
		}).catch((err) => {
			setErro(true)
		}).finally(() => {
			setLoading(false)
		})
	};

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			justifyContent: "center",
		},
	});

	return (
		<CustomContext.Provider
			value={{
				storeId: store,
				setStore: setStore as any,
				catalogName: catalogName,
				meiId: meiId,
				meiInfo: meiInfo
			}}
		>
			{loading && (
				<View style={styles.container}>
					<View>
						<ActivityIndicator size="large" color={meiInfo.themeColor} />
					</View>
				</View>
			)}

			{erro && (
				<>
					<Header hide={true} />
					<EmptyStore>
						<EmptyMessage>
							Loja não encontrada.
						</EmptyMessage>
					</EmptyStore>
				</>
			)}

			{!loading && !erro && children}
		</CustomContext.Provider>
	);
};
