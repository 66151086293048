import React, { useState } from "react";
import { Image, Linking } from "react-native"
import {
	Modal,
	Container,
	Content,
	BackContainer,
	BackText,
	AddToCartContainer,
	OverlayOffer,
	ProductBox,
	OfferLabel,
	ProductImage,
	NoImage,
	ImageWrapper,
	ProductName,
	DescriptionBox,
	DescriptionText,
	ContainerMessage,
	Message,
	PriceWrapper,
	RiskedPrice,
	FinalProductPrice,
	Space,
	ButtonContainerAdd,
	ButtonsContainer,
	ContinueBuying,
	ContinueBuyingBorder,
	TextAddToCart,
	AreaAddProduct,
	Overlay,
	ContainerWhatsApp,
	TextLinkWhatsApp,
} from "./styles";
import { NumberToCurrency } from "../../utils/numberToCurrency";
import { ProductItem } from "../../store/category-product";
import { IMAGE_URL_DEFAULT } from "../../utils/contants";
import { useCart } from "../../hooks/use-cart";
import { NavigationProp, useNavigation } from "@react-navigation/native";

import Whatsapp from "../../../assets/whatsapp-icon.png";
import { useStore } from "../../hooks/use-store";
import { calcularDesconto } from "../Product";
import { theme } from "../../global/theme";

type Navigation = NavigationProp<any>;

interface Props {
	product: ProductItem;
	setProductSelected: (value: ProductItem | null) => void
}

export const ModalProductDetails: React.FC<Props> = ({
	product,
	setProductSelected
}) => {
	const cart = useCart();
	const paramStore = useStore();
	const [productQuantity] = useState(1);
	const navigation = useNavigation<Navigation>();
	const show_as_unavailable = paramStore.meiInfo.productVisibility === "show_as_unavailable" && product.qtdStock <= 0

	const handleAddToCart = async () => {
		cart.updateCartItem(product, productQuantity, false, false, false);
		setProductSelected(null)
		navigation.navigate("Cart");
	}

	async function redirectToStore() {
		let supportURL = `https://api.whatsapp.com/send?phone=+55${paramStore?.meiInfo?.phone}&text=Olá preciso de ajuda.`;
		await Linking.openURL(supportURL);
	}

	return (
		<Modal transparent animationType="slide">
			<Overlay>
				<Container>
					<BackContainer>
						<ProductName>
							{product?.productName}
						</ProductName>
					</BackContainer>
					<Content showsVerticalScrollIndicator={false}>
						<OverlayOffer>
							<ProductBox>
								<ImageWrapper>
									<ProductImage
										source={{
											uri: product?.image || IMAGE_URL_DEFAULT
										}}
										resizeMode="contain"
										testID="image"
									/>
								</ImageWrapper>
								<PriceWrapper>
									{product?.price_final_in_cents <
										product.price && (
											<RiskedPrice testID="old_price">
												{NumberToCurrency(product.price)}
											</RiskedPrice>
										)}
									<FinalProductPrice>
										{NumberToCurrency(product?.price_final_in_cents || product.price)}
									</FinalProductPrice>
								</PriceWrapper>
							</ProductBox>
						</OverlayOffer>

						{product?.price_final_in_cents <
							product.price && (
								<ContainerMessage color={paramStore.meiInfo.themeColor}>
									<Message>
										{calcularDesconto(product.price, (product?.price_final_in_cents || product.price))}%
									</Message>
								</ContainerMessage>
							)}

						<DescriptionBox>
							<DescriptionText>{product?.description || "Nenhuma descrição para esse produto"}</DescriptionText>
						</DescriptionBox>
					</Content>
					{paramStore?.meiInfo?.phone && (
						<ContainerWhatsApp>
							<Image source={Whatsapp} style={{ width: 20, height: 20 }} />
							<TextLinkWhatsApp>
								Dúvidas sobre o produto? Entre em contato pelo <TextLinkWhatsApp onPress={redirectToStore} style={{ textDecorationLine: "underline", fontWeight: "bold" }}>WhatsApp</TextLinkWhatsApp>
							</TextLinkWhatsApp>
						</ContainerWhatsApp>
					)}
					<ButtonsContainer
						style={{
							shadowColor: '#000',
							shadowOffset: {
								width: 0,
								height: 11,
							},
							shadowOpacity: 0.55,
							shadowRadius: 14.78,
							elevation: 22,
						}}>
						{paramStore?.meiInfo?.onlinePurchasesEnabled && !show_as_unavailable && (
							<ContinueBuying type="secondary" onPress={handleAddToCart} color={paramStore.meiInfo.themeColor}>
								Adicionar ao carrinho
							</ContinueBuying>
						)}
						<ContinueBuyingBorder
							textStyle={{ color: theme(paramStore.meiInfo.themeColor).colors.primary, opacity: 0.6 }}
							type="primary_ghost"
							onPress={() => setProductSelected(null)}
							color={paramStore.meiInfo.themeColor}>
							Fechar
						</ContinueBuyingBorder>
					</ButtonsContainer>
				</Container>
			</Overlay>
		</Modal>
	);
};
