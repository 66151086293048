import { useMutation } from "@tanstack/react-query";
import api from "../../services/api";

interface CartItem {
  product_id: string;
  quantity: number;
}

interface Client {
  name: string;
  cpf: string;
  email: string;
  phone: string;
  cep: string;
  city: string;
  state: string;
  logradouro: string;
  number: string;
  district: string;
  complement?: string;
  codigoIbge?: string
}

export interface CreateOrderDto {
  items: CartItem[];
  client: Client;
  totalFormated: string
  totalUnformated: number
}

const sendPaymentsFetch = async (data: CreateOrderDto) => {
  const response = await api.post(`/catalogSettings/sales`, data);
  return response.data;
};

const mutationFn = async (data: CreateOrderDto) => {
  return sendPaymentsFetch(data);
};

export const useSendPaymentsMutation = () => {
  return useMutation(mutationFn);
};
