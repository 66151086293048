import React, { Dispatch, SetStateAction } from 'react';
import { Modal as ReactNativeModal } from 'react-native';
import { Pressable } from 'react-native';

import { FontAwesome } from '@expo/vector-icons';

import {
  Container,
  Content,
  Title,
  Description,
  Actions,
  TextButton,
  IconAlert,
  IconContaine,
  Overlay
} from './styles';
import { useStore } from '../../hooks/use-store';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  description: string;
  action: VoidFunction;
  oneButton?: boolean
  onOkButtonText?: string
  onCancelButtonText?: string
  type: "info" | "erro" | "success"
}

export const ModalAlert: React.FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  title,
  description,
  action,
  oneButton = true,
  onOkButtonText,
  onCancelButtonText,
  type
}) => {
  const paramStore = useStore();
  const modalAction = () => {
    action && action();
    setIsOpen(false);
  };
  //check-circle
  return (
    <ReactNativeModal
      animationType="slide"
      visible={isOpen}
      transparent={true}
      onRequestClose={() => {
        setIsOpen(false);
      }}
      testID={""}
    >
      <Overlay>
        <Container>
          <IconContaine>
            {type === "success" && (
              <FontAwesome name={"check-circle"} size={50} color="green" />
            )}
            {type === "erro" && (
              <FontAwesome name={"info-circle"} size={50} color="red" />
            )}
            {type === "info" && (
              <FontAwesome name={"info-circle"} size={50} color="orange" />
            )}
          </IconContaine>
          <Content>
            <Title testID="modal_title">{title}</Title>
            <Description testID="modal_description">{description}</Description>
          </Content>

          <Actions>
            {!oneButton && (
              <Pressable
                onPress={() => setIsOpen(false)}
                style={{
                  width: '50%',
                  padding: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRightWidth: 1,
                  borderRightColor: '#949494',
                }}
                testID="modal_cancel_btn"
              >
                <TextButton color={paramStore.meiInfo.themeColor}>{onCancelButtonText || "CANCELAR"}</TextButton>
              </Pressable>
            )}
            <Pressable
              onPress={modalAction}
              style={{
                width: !oneButton ? '50%' : "100%",
                padding: 20,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              testID="modal_ok_button"
            >
              <TextButton color={paramStore.meiInfo.themeColor}>{onOkButtonText || "OK"}</TextButton>
            </Pressable>
          </Actions>
        </Container>
      </Overlay>
    </ReactNativeModal>
  );
};

export default ModalAlert;
