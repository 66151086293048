import styled, { css } from "styled-components/native";
import { theme } from "../../global/theme";

interface Props {
	selected: boolean;
	color: string
}

export const Container = styled.View`
	flex: 1;
	background-color: ${theme().colors.white};
`;

export const Content = styled.View`
	flex: 1;
`;

export const CategoriesContainer = styled.ScrollView`
	margin-top: 20px;
`;

export const TitleCategories = styled.Text`
	padding-bottom: 14px;
	padding-left: 25px;
	font-size: 18px;
	font-family: ${theme().fonts.bold};
`;

export const CategoriesContainerContent = styled.ScrollView`
	padding-left: 25px;
	flex-direction: row;
	margin-bottom: 25px;
`;

export const CategoriesContent = styled.TouchableOpacity<Props>`
	padding: 10px 20px;
	border-radius: 10px;
	justify-content: center;
	margin-right: 14px;
	width: 200px;
	border: 1px solid ${theme().colors.border};
	background-color: ${theme().colors.white};
	${(props) => {
		if (props.selected) {
			return css`
				background-color: ${theme(props.color).colors.primary};
				opacity: 0.5;
			`;
		}
	}}
`;

export const TextCategories = styled.Text<Props>`
	font-size: 12px;
	font-family: ${theme().fonts.bold};
	color: ${theme().colors.black};
	text-align: center;
	${(props) => {
		if (props.selected) {
			return css`
				color: ${theme().colors.white};
			`;
		}
	}}
`;

export const Products = styled.View`
	justify-content: center;
	align-items: center;
	display: flex;
	width: 100%;
`;

export const ContainerBtnSearch = styled.View`
	display: flex;
	position: absolute;
	align-items: flex-end;
	bottom: 38px;
	right: 21px;
`;

export const ButtonContainer = styled.View`
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 50px;
`;

export const TextLoadingCategory = styled.Text`
	font-size: 15px;
	font-family: ${theme().fonts.bold};
`;
export const EmptyStore = styled.View`
	flex: 1;
	align-items: center;
	justify-content: center;
	margin: 20px;
`;

export const EmptyMessage = styled.Text`
	color: ${theme().colors.black};
	font-size: 14px;
	font-family: ${theme().fonts.regular};
`;

export const ContainerIcons = styled.View`
	padding: 15px;
	align-items: flex-end;
	background-color: ${theme().colors.white};
	justify-content: space-between;
	display: flex;
	flex-direction: row;
	padding-right: 30px;
	padding-left: 30px;
`;

export const TextContacts = styled.Text`
	color: ${theme().colors.black};
	font-size: 18px;
	font-family: ${theme().fonts.bold};
`;

export const ButtonContacts = styled.TouchableOpacity`
	border-radius: 35px;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 10;
`;

export const ContainerContactAndCart = styled.View`
	flex-direction: row;
	gap: 20px;
`;