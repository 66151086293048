import React, { Dispatch, SetStateAction } from 'react';
import { Linking, Modal as ReactNativeModal } from 'react-native';
import { Image } from 'react-native';

import { FontAwesome, Feather } from '@expo/vector-icons';

import Whatsapp from "../../../assets/whatsapp-icon.png";

import {
  Container,
  Content,
  Title,
  Description,
  Actions,
  TextButton,
  IconAlert,
  IconContaine,
  Overlay,
  ContainerDescription,
  ContentDescription,
  CloseIcon,
  ModalAreaHeader,
  IconCloseModal
} from './styles';
import { theme } from '../../global/theme';
import { useStore } from '../../hooks/use-store';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ModalContacts: React.FC<ModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const paramStore = useStore();

  const modalAction = () => {
    console.log("clicou")
    setIsOpen(false);
  };

  async function redirectToStore() {
    let supportURL = `https://api.whatsapp.com/send?phone=+55${paramStore?.meiInfo?.phone}&text=Olá preciso de ajuda.`;
    await Linking.openURL(supportURL);
  }

  async function redirectToInstagram() {
    const URLRecorte = paramStore?.meiInfo?.instagram?.startsWith("@") ?
      paramStore?.meiInfo?.instagram?.replace("@", "") :
      paramStore?.meiInfo?.instagram
    let supportURL = `https://www.instagram.com/${URLRecorte}`;
    await Linking.openURL(supportURL);
  }

  async function redirectToEmail() {
    let email = paramStore?.meiInfo?.email;

    let emailURL = `mailto:${email}`;
    await Linking.openURL(emailURL);
  }

  return (
    <ReactNativeModal
      animationType="slide"
      visible={isOpen}
      transparent={true}
      onRequestClose={() => {
        setIsOpen(false);
      }}
      testID={""}
    >
      <Overlay>
        <Container>
          <ModalAreaHeader>
            <IconCloseModal onPress={modalAction} color={paramStore.meiInfo.themeColor}>
              <CloseIcon source={require('../../../assets/close.svg')} />
            </IconCloseModal>
          </ModalAreaHeader>
          <Content>
            <Title testID="modal_title">ENTRE EM CONTATO</Title>
            <ContainerDescription>
              {paramStore?.meiInfo?.phone && (
                <ContentDescription onPress={redirectToStore}>
                  <Image source={Whatsapp} style={{ width: 20, height: 20 }} />
                  <Description testID="modal_description">
                    +{paramStore?.meiInfo?.phone}
                  </Description>
                </ContentDescription>
              )}
              {paramStore?.meiInfo?.instagram?.replace("@", "") && (
                <ContentDescription onPress={redirectToInstagram}>
                  <FontAwesome name={"instagram"} size={25} color={theme().colors.black} />
                  <Description testID="modal_description">
                    {paramStore?.meiInfo?.instagram}
                  </Description>
                </ContentDescription>
              )}
              {paramStore?.meiInfo?.email && (
                <ContentDescription onPress={redirectToEmail}>
                  <Feather name={"mail"} size={25} color={theme().colors.black} />
                  <Description testID="modal_description">
                    {paramStore?.meiInfo?.email}
                  </Description>
                </ContentDescription>
              )}
              {paramStore?.meiInfo?.address && (
                <ContentDescription disabled>
                  <Feather name={"map-pin"} size={25} color={theme().colors.black} />
                  <Description testID="modal_description">
                    {paramStore.meiInfo.address}
                  </Description>
                </ContentDescription>
              )}
            </ContainerDescription>
          </Content>
        </Container>
      </Overlay>
    </ReactNativeModal>
  );
};

export default ModalContacts;
