import { Animated } from 'react-native';
import styled from 'styled-components/native';
import { theme } from '../../global/theme';

export const Container = styled(Animated.View)`
  width: 95%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5px;
  overflow: hidden;
  border-radius: 8px;
`;

export const Message = styled.Text`
  color: #fff;
  font-size: 13px;
  font-family: ${theme().fonts.bold};
  margin-left: 16px;
  padding: 10px 0px;
`;
