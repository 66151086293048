import React from 'react';
import { FontAwesome5 } from '@expo/vector-icons';
import { Container, Message } from './styles';
import { theme } from '../../global/theme';

interface Props {
  message: string;
  color: string
  icon: "check-circle" | "window-close"
}

export const SnackBar: React.FC<Props> = ({ message, color, icon }) => {

  return (
    <Container style={{ height: 50, backgroundColor: color || theme().colors.green }}>
      <FontAwesome5 name={icon || "check-circle"} size={20} color="white" />
      <Message ellipsizeMode="tail" numberOfLines={1} testID="nb_message">
        {message}
      </Message>
    </Container>
  );
};
