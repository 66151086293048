import React from "react";
import {
	useFonts,
	Nunito_400Regular,
	Nunito_500Medium,
	Nunito_600SemiBold,
	Nunito_700Bold,
} from "@expo-google-fonts/nunito";
import { NavigationContainer, useNavigationContainerRef } from "@react-navigation/native";
import AppLoading from "expo-app-loading";
import { Provider } from "react-native-paper";
import { CartProvider } from "./src/providers/cart-provider";
import { CustomProvider } from "./src/providers/custom-provider";
import { ModalProvider } from "./src/providers/modal-provider";
import { NotificationBarProvider } from "./src/providers/notification-bar-provider";
import Routes from "./src/routes/routes";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import { SENTRY_DSN } from "./src/utils/contants";
import * as Sentry from '@sentry/react';
import pkg from "./package.json";

import 'react-lazy-load-image-component/src/effects/blur.css';

export default function App() {
	const navigationRef = useNavigationContainerRef();

	let [fontsLoaded] = useFonts({
		Nunito_400Regular,
		Nunito_500Medium,
		Nunito_600SemiBold,
		Nunito_700Bold,
	});

	Sentry.init({
		dsn: SENTRY_DSN,
		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: [
					'localhost',
					/^https:\/\/api\.servemei\.com\.br/,
				],
			}),
			new Sentry.Integrations.TryCatch(),
			new Sentry.Integrations.GlobalHandlers({
				onerror: true,
				onunhandledrejection: true,
			}),
			new Sentry.Integrations.FunctionToString(),
		],
		release: pkg.version,
		environment: "production",
		enableTracing: true,
		tracesSampleRate: 1.0,
		autoSessionTracking: true,
		enabled: false
	});

	const queryClient = new QueryClient()

	if (!fontsLoaded) {
		return <AppLoading />;
	}

	return (
		<Provider>
			<CustomProvider>
				<NavigationContainer ref={navigationRef}>
					<NotificationBarProvider>
						<QueryClientProvider client={queryClient}>
							<ModalProvider>
								<CartProvider>
										<Routes />
								</CartProvider>
							</ModalProvider>
						</QueryClientProvider>
					</NotificationBarProvider>
				</NavigationContainer>
			</CustomProvider>
		</Provider>
	);
}
