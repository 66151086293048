import { CommonActions, useNavigation } from "@react-navigation/native";
import React, { useState, useMemo, useEffect } from "react";
import { CartContext } from "../contexts";
import { useModal } from "../hooks";
import { useNotificationBar } from "../hooks/use-notification-bar";
import { CartItem } from "../models/cart";

import { ProductItem } from "../store/category-product";
import { theme } from "../global/theme";

export const CartProvider = ({ children }: React.PropsWithChildren) => {
	const [cart, setCart] = useState<CartItem[]>([]);
	const [isAlreadyCreated, setIsAlreadyCreated] = useState(false);
	const { notify } = useNotificationBar();
	const modal = useModal();
	const navigation = useNavigation();
	const itemsTotal = useMemo(
		() => cart.reduce((total, item) => total + item.quantity, 0),
		[cart]
	);

	const cartIds = useMemo(() => cart.map((item) => item._id), [cart]);

	useEffect(() => {
		if (itemsTotal > 0) {
			setIsAlreadyCreated(false);
		}
	}, [itemsTotal]);

	const updateCartItem = async (
		planogram: ProductItem,
		quantity: number,
		redirect?: boolean,
		clearCart?: boolean,
		confirmRemoveItem?: boolean,
	): Promise<boolean> => {
		const itemSearch = cart.find((item) => item._id === planogram._id);
		if (!itemSearch) {
			if (quantity <= 0) return false;
			setCart([
				...cart,
				{
					...planogram,
					quantity,
				},
			]);
			if (!redirect) notify(planogram.productName + " adicionado no carrinho".toUpperCase(), theme().colors.green, "check-circle");
		} else {
			if (quantity + itemSearch.quantity > 0)
				setCart(
					cart.map((item) => {
						if (item._id !== itemSearch._id) return item;
						return {
							...item,
							quantity: quantity + itemSearch.quantity,
						};
					})
				);
			else {
				if (!confirmRemoveItem) {
					notify(planogram.productName + " removido no carrinho".toUpperCase(), theme().colors.red, "check-circle");
					await updateCartItem(planogram, -1, false, false, true);
				} else {
					const newItems = cart.filter(
						(item) => item._id !== itemSearch._id
					);
					setCart(newItems);
				}
			}
		}
		if (!!redirect) {
			navigation.dispatch(
				CommonActions.navigate({
					name: "Carrinho",
				})
			);
		}
		return true;
	};

	const clearCart = () => {
		setCart([]);
	};

	const getCart = () => cart;


	const value = useMemo(() => {
		return {
			updateCartItem,
			setIsAlreadyCreated,
			isAlreadyCreated,
			clearCart,
			getCart,
			itemsTotal,
			cartIds,
		};
	}, [cart]);


	return (
		<CartContext.Provider value={value}>
			{children}
		</CartContext.Provider>
	);
};
