import React, { useState } from 'react';
import { NotificationBarContext } from '../contexts/notification-bar-context';
import { SnackBar } from '../components/SnackBar';
import { View } from 'react-native';
import { theme } from '../global/theme';

export const NotificationBarProvider = ({ children }: React.PropsWithChildren) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [message, setMessage] = useState('');
  const [color, setColor] = useState(theme().colors.green)
  const [icon, setIcon] = useState<"check-circle" | "window-close">("check-circle")

  const createNotification = (message: string, color: string, icon: "check-circle" | "window-close", time = 800) => {
    timer && clearTimeout(timer);
    setMessage(message);
    setIcon(icon)
    setColor(color)
    setTimer(setTimeout(() => {
      setMessage("")
      setTimer(undefined)
    }, time));
  };

  return (
    <NotificationBarContext.Provider
      value={{ notify: createNotification }}
    >
      {children}
      <View style={{ alignItems: "center", display: !message ? "none" : "flex" }}>
        <SnackBar message={message} color={color} icon={icon} />
      </View>
    </NotificationBarContext.Provider>
  );
};
