import styled from "styled-components/native";
import { theme } from "../../global/theme";
import { Button } from "../../components/Button";

export const Container = styled.View`
  flex: 1;
  background-color: ${theme().colors.white};
  margin-top: 10px;
`;

export const Content = styled.View`
  flex: 1;
`;

export const CartContentContainer = styled.View`
  width: 100%;
  height: 80%;
  justify-content: space-around;
`;

export const HeaderCart = styled.View`
  width: 100%;
  margin: 0 19px;
  margin-top: 41px;
  position: relative;
`;

export const HeaderPrevLink = styled.Text`
  font-size: 15px;
  color: ${theme().colors.primary};
  font-family: ${theme().fonts.bold};
  text-decoration: underline;
  text-decoration-color: ${theme().colors.primary};
  position: absolute;
  left: 0;
`;

export const StoreAreaInfo = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 26px;
  margin: 30px 19px 0px 19px;
`;

export const StoreLogo = styled.Image`
  width: 70px;
  height: 70px;
  border-radius: 7px;
`;

export const StoreAreaTitle = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 14px;
`;

export const StoreTitle = styled.Text`
  color: ${theme().colors.tertiary};
  font-family: ${theme().fonts.semiBold};
  font-size: 18px;
`;

export const StoreAddress = styled.Text`
  font-size: 13px;
  font-family: ${theme().fonts.regular};
`;

export const TitleCart = styled.Text`
  font-size: 18px;
  font-family: ${theme().fonts.bold};
  text-align: center;
  color: ${theme().colors.tertiary};
`;

export const EmptyCartContainer = styled.View`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmptyCartIcon = styled.Image`
  width: 210px;
  height: 210px;
`;

export const NoItemsContainer = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${theme().colors.white};
`;

export const NoItemsText = styled.Text`
  width: 317px;
  font-size: 18px;
  text-align: center;
  padding-top: 20px;
  font-family: ${theme().fonts.semiBold};
  color: ${theme().colors.black};
`;

export const BtnContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-left: 19px;
  align-items: center;
  padding-right: 19px;
  position: fixed;
  bottom: 10px;
  background-color: #fff;
  gap: 10px;
`;

export const BtnArea = styled.View`
  width: 100%;
`;

export const AddCpfText = styled.Text`
  width: 220px;
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
  font-family: ${theme().fonts.regular};
  color: ${theme().colors.black};
`;

export const CpfContainerContent = styled.TextInput`
  display: flex;
  margin-bottom: 45px;
  width: 353px;
  height: 55px;
  border: 0.8px solid ${theme().colors.border};
  border-radius: 10px;
  box-sizing: border-box;
  background-color: ${theme().colors.white};
`;

export const ConfirmButton = styled(Button) <{ color: string }>`
  background-color: ${({ color }) => theme(color).colors.primary};
  width: 100%;
`;

export const TextConfirmButton = styled.Text`
  font-size: 18px;
  font-family: ${theme().fonts.bold};
  color: ${theme().colors.white};
  text-align: center;
`;

export const TotalContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;
  background-color: ${theme().colors.white};
  padding: 13px 19px;
  border-top-width: 0.5px;
  border-bottom-width: 0.5px;
  border-color: #dadada;
  margin-bottom: 1px;
`;

export const TotalContainerContent = styled.View`
  display: flex;
  align-items: center;
`;

export const TotalContainerContentValue = styled.View`
  display: flex;
  align-items: center;
`;

export const TotalContainerContentText = styled.Text`
  font-family: ${theme().fonts.regular};
  font-size: 15px;
  color: ${theme().colors.tertiary};
  text-align: center;
`;

export const TotalContainerContentTextValue = styled.Text<{ isLeft?: boolean, isRigth?: boolean, color: string }>`
	font-family: ${theme().fonts.bold};
	font-size: 18px;
	color: ${({ color }) => theme(color).colors.primary};
	text-align: center;
  opacity: 0.6;
	${(props) => props.isRigth && `
		margin-right: 19px;
	`}
	${(props) => props.isLeft && `
		margin-left: 19px;
	`}
`;

export const ItemsScroll = styled.ScrollView`
  background-color: ${theme().colors.white};
  margin-bottom: 10px;
`;

export const CardItemsArea = styled.View`
  padding: 0 19px;
`;

export const ContainerContentEmptyCart = styled.View`
  display: flex;
  height: 80%;
  align-items: center;
  justify-content: space-between;
`;

export const ContentEmptyCart = styled.View`
  display: flex;
  height: 80%;
  align-items: center;
  justify-content: center;
`;

export const ContentCPF = styled.View`
  width: 100%;
  align-items: center;
`;

export const ContentButtonProducts = styled.View`
  width: 100%;
  margin-top: 80px;
`;

interface LineContainerProps {
  first?: boolean;
  isLast?: boolean;
}

export const LineArea = styled.View``;

export const LineContainer = styled.View<LineContainerProps>`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-horizontal: 19px;
  margin-vertical: 4px;
  ${(props) =>
    !!props.first &&
    `
  `}
  ${(props) =>
    props.isLast &&
    `
		border-bottom-width: 0.5px;
		border-color: #DADADA;
		padding-bottom: 15px;
	`}
`;

export const PurchaseDetails = styled.View<{ padding?: boolean }>`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-bottom-width: 0.5px;
	  border-color: #DADADA;
		padding-Vertical: 14px;
    border-top-width: 0.5px;

    ${(props) =>
    !props.padding &&
    `
    margin: 14px 19px 190px 19px;
    padding-Vertical: 0px;
    border-bottom-width: 0px;
    border-color: #fff;
	`}
`;

export const LineText = styled.Text`
  color: ${theme().colors.gray};
  font-family: ${theme().fonts.medium};
  font-size: 14px;
`;

export const TextAnother = styled.Text`
  font-size: 11px;
  font-family: ${theme().fonts.regular};
  color: ${theme().colors.black};
  margin-top: 10px;
`;

export const ChangeButton = styled(Button) <{ color: string }>`
  width: 100%;
  border-color: ${({ color }) => theme(color).colors.primary};
`;

export const TextChange = styled.Text`
  width: 100px;
  font-size: 14px;
  font-family: ${theme().fonts.medium};
  color: ${theme().colors.primary};
  text-align: center;
`;
export const PaymentLineContainer = styled.View`
  padding-horizontal: 20px;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 16px;
  border-bottom-width: 0.5px;
  border-bottom-color: ${theme().colors.border};
  padding-bottom: 32px;
	margin: 0px 0px 180px 0px;
`;
export const PaymentDetailsContainer = styled.View`
  flex: 1;
  margin-horizontal: 10px;
  justify-content: center;
`;
export const PaymentDetailsTitle = styled.Text`
   font-size: 14px;
  font-family: ${theme().fonts.regular};
  color: ${theme().colors.gray};
`;
export const PaymentDetailsDescription = styled.Text`
   font-size: 14px;
	 font-family: ${theme().fonts.regular};
  color: ${theme().colors.tertiary};
`;
interface PaymentChangeTextProps {
  color?: string | null;
}

export const PaymentChangeText = styled.Text<PaymentChangeTextProps>`
   font-size: 14px;
  font-family: ${theme().fonts.bold};
  text-decoration: underline;
  text-decoration-color: ${({ color }) =>
    color ? color : theme().colors.primary};
  color: ${({ color }) => (color ? color : theme().colors.primary)};
`;
export const PaymentTitle = styled.Text`
   font-size: 18px;
  padding-left: 20px;
  font-family: ${theme().fonts.semiBold};
  margin-top: 23px;
	margin-bottom: 15px;
`;
export const IconContaine = styled.View``;

export const NoteIcon = styled.Image`
	width: 16px;
	height: 16px;
`;