import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { NumberToCurrency } from "../../utils/numberToCurrency";
import { QuantityPicker } from "../QuantityPicker";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { FontAwesome } from '@expo/vector-icons';

import {
	Container,
	DescriptionContainer,
	DiscountIcon,
	ImageContainer,
	RiskedPrice,
	PriceText,
	QuantityContainer,
	Title,
	ContainerMessage,
	Message,
	ImageContent
} from "./styles";
import { IMAGE_URL_DEFAULT } from "../../utils/contants";
import { useStore } from "../../hooks/use-store";
import { theme } from "../../global/theme";

export function calcularDesconto(valorOriginal: number, valorComDesconto: number): string {
	const desconto: number = valorOriginal - valorComDesconto;
	const porcentagemDesconto: number = (desconto / valorOriginal) * 100;
	return porcentagemDesconto.toFixed(1); 
}

interface ProductProps {
	imgUrl: string;
	title: string;
	price_list: number;
	price_final: number;
	quantity: number;
	maxQuantity: number;
	onQuantityChange: (qnty: number) => void;
	quantityOpen?: boolean;
	onQuantityOpen: () => void;
	onQuantityPress: () => void;
	style?: StyleProp<ViewStyle>;
	onPress: () => void;
	testID?: string;
}

export const Product: React.FC<ProductProps> = ({
	imgUrl,
	title,
	price_list,
	price_final,
	quantity,
	maxQuantity,
	onQuantityChange,
	quantityOpen,
	onQuantityOpen,
	onQuantityPress,
	style,
	onPress,
	testID,
}) => {
	const inSale = price_final < price_list;
	const paramStore = useStore();
	const show_as_unavailable = paramStore.meiInfo.productVisibility === "show_as_unavailable" && maxQuantity <= 0

	return (
		<Container
			style={style as any}
			onPress={onPress}
			testID={`btn_product_${testID}`}
		>
			<ImageContainer>
				<ImageContent color={paramStore.meiInfo.themeColor} logo={!!imgUrl}>
					{imgUrl ? (
						<LazyLoadImage
							alt={title}
							src={imgUrl || IMAGE_URL_DEFAULT}
							effect="blur"
							style={{ objectFit: 'contain', width: '100%', height: '100%' }}
						/>
					) : (
						<FontAwesome
							name={"camera"}
							size={40}
							color={theme().colors.white}
							style={{ marginTop: -20 }} />
					)}
				</ImageContent>

				{inSale && (
					<ContainerMessage color={paramStore.meiInfo.themeColor}>
						<Message>
							{calcularDesconto(price_list, price_final)}%
						</Message>
					</ContainerMessage>
				)}

				{show_as_unavailable && (
					<ContainerMessage color={paramStore.meiInfo.themeColor} unavailable={show_as_unavailable}>
						<Message>
							Produto indisponível
						</Message>
					</ContainerMessage>
				)}

				{paramStore?.meiInfo?.onlinePurchasesEnabled && (
					<QuantityContainer>
						<QuantityPicker
							type="compact"
							open={quantityOpen}
							value={quantity}
							maxValue={quantity + 1}
							onChange={onQuantityChange}
							onOpen={onQuantityOpen}
							onPricePress={onQuantityPress}
							testID={testID}
							color={paramStore.meiInfo.themeColor}
							unavailable={show_as_unavailable}
						/>
					</QuantityContainer>
				)}
			</ImageContainer>
			<DescriptionContainer>
				<Title
					numberOfLines={3}
					testID={`product_${testID}_title`}
				>
					{title}
				</Title>
				{inSale && (
					<RiskedPrice testID={`product_${testID}_old_price`}>
						{NumberToCurrency(price_list)}
					</RiskedPrice>
				)}
				<PriceText
					isDiscount={inSale}
					testID={`product_${testID}_price`}
				>
					{NumberToCurrency(price_final)}
				</PriceText>
			</DescriptionContainer>
		</Container>
	);
};
