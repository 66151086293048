import { createContext } from "react";
import { CartItem } from "../models/cart";
import { ProductItem } from "../store/category-product";

interface CartContextProps {
	updateCartItem: (
		planogram: ProductItem,
		quantity: number,
		redirect: boolean,
		clearCart: boolean,
		confirmRemoveItem: boolean,
	) => Promise<boolean>;
	clearCart: () => void;
	getCart: () => CartItem[];
	cartIds: string[];
	itemsTotal: number;
	isAlreadyCreated: boolean;
	setIsAlreadyCreated: (bool: boolean) => void;
}

export const CartContext = createContext({} as CartContextProps);
